import React, {useEffect, useState} from 'react';
import {RedocStandalone} from 'redoc';
import {css} from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Api = (props) => {
    const {service, file} = props;
    const [state, setState] = useState({spec: null, error: null, isPending: true});

    useEffect(() => {
        function fetchSpec() {
            const DEBUG_SERVER = process.env['REACT_APP_DEBUG_SERVER'] || '';
            const url = DEBUG_SERVER + '/spec?service=' + service + '&file=' + file
            const options = {mode: 'cors'}
            return fetch(url, options)
                .then(response => {
                    if (response.status !== 200) {
                        // noinspection JSCheckFunctionSignatures
                        setState({spec: null, error: `response status is ${response.status}`, isPending: false});
                        return;
                    }
                    response.json()
                        .then(spec => {
                            setState({spec: spec, error: null, isPending: false});
                        })
                        .catch(err => {
                            setState({spec: null, error: err, isPending: false});
                        });
                })
                .catch(err => {
                    setState({spec: null, error: err, isPending: false});
                });
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchSpec();
    }, [service, file]);

    if (state.error !== null) return <pre>Error:<br/>{state.error}</pre>;

    if (state.isPending || state.spec === undefined) return (<div className="sweet-loading">
        <BeatLoader
            css={override}
            color={"#d95357"}
            loading={true}
        />
    </div>);

    return <RedocStandalone spec={state.spec} onLoaded={error => {
        if (error) {
            console.log(error);
        }
    }}/>;
}

export default Api;
