import React, {useEffect, useState} from 'react';
import './App.css';
import Home from './Home';
import Api from './Api';
import {Route, Switch} from 'react-router';

const all_apis =
    [
        {
            "id": "poma-api",
            "title": "Public Workforce Flexibilisation API v1",
            "route": "/poma-api",
            // "service": "ct-poma-api.k8s.polypoint-dev.com",
            "service": "poma-api",
            "file": "poma-api.yaml",
            "private": false,
            "section": "Employment placement",
        },
        {
            "id": "integration-partner-api",
            "title": "Integration Workforce Flexibilisation API v1",
            "route": "/integration-partner-api",
            "service": "poma-api",
            "file": "integration-partner-api.yaml",
            "private": false,
            "section": "Employment placement",
        },
        {
            "id": "booking-partner-api",
            "title": "Booking Partner API v1",
            "route": "/booking-partner-api",
            "service": "poma-api",
            "file": "booking-partner-api.yaml",
            "private": false,
            "section": "Employment placement",
        },
        {
            "id": "external-workforce-consumer-api",
            "title": "External Workforce Consumer API",
            "route": "/external-workforce-consumer-api",
            "service": "poma-api",
            "file": "external-workforce-consumer-api.yaml",
            "private": false,
            "section": "External Workforce",
        },
        {
            "id": "external-workforce-provider-api",
            "title": "External Workforce Provider API",
            "route": "/external-workforce-provider-api",
            "service": "poma-api",
            "file": "external-workforce-provider-api.yaml",
            "private": false,
            "section": "External Workforce",
        },
        {
            "id": "internal-poma-api",
            "title": "Internal Workforce Flexibilisation API",
            "route": "/internal-poma-api",
            "service": "poma-api",
            "file": "internal-poma-api.yaml",
            "private": true,
            "section": "Employment placement",
        },
        {
            "id": "health-professional-api",
            "title": "Mobile/HealthProfessional API v1",
            "route": "/health-professional-api",
            "service": "poma-api",
            "file": "health-professional-api.yaml",
            "private": true,
            "section": "Employment placement",
        },
        {
            "id": "time-recording-api",
            "title": "Mobile/TimeRecording API v1",
            "route": "/time-recording-api",
            "service": "poma-api",
            "file": "time-recording-api.yaml",
            "private": true,
            "section": "Employment placement",
        },
        {
            "id": "legacy-time-recording-api",
            "title": "Legacy  TimeRecording API v1",
            "route": "/legacy-time-recording-api",
            "service": "poma-api",
            "file": "legacy-time-recording-api.yaml",
            "private": true,
            "section": "Employment placement",
        },
        {
            "id": "tpms-api",
            "title": "Health Service Consumer API",
            "route": "/tpms-consumer-api",
            // "service": "ct-appointment-api.k8s.polypoint-dev.com",
            "service": "tpms-api",
            "file": "tpms-api.yaml",
            "private": false,
            "section": "Health Service Consumer",
        },
        {
            "id": "tpms-reservation-api",
            "title": "Reservation API",
            "route": "/tpms-reservation-api",
            "service": "tpms-api",
            "file": "tpms-reservation-api.yaml",
            "private": false,
            "section": "Health Service Provider",
        },
        {
            "id": "tpms-attachment-api",
            "title": "TPMS Attachment API",
            "route": "/tpms-attachment-api",
            "service": "tpms-api",
            "file": "tpms-attachment-api.yaml",
            "private": false,
            "section": "Health Service Provider",
        },
        {
            "id": "tpms-service-provider-api",
            "title": "TPMS Service Provider API",
            "route": "/tpms-service-provider-api",
            "service": "tpms-api",
            "file": "tpms-service-provider-api.yaml",
            "private": false,
            "section": "Health Service Provider",
        },
        {
            "id": "portal-api",
            "title": "PolyPortal Public API",
            "route": "/portal-api",
            "service": "portal-api",
            "file": "portal-api.yaml",
            "private": false,
            "section": "Poly Portal",
        },
        {
            "id": "portal-events-producer-api",
            "title": "PolyPortal Event Producer API",
            "route": "/portal-events-producer-api",
            "service": "portal-api",
            "file": "portal-events-producer-api.yaml",
            "private": false,
            "section": "Poly Portal",
        },
        {
            "id": "portal-events-consumer-api",
            "title": "PolyPortal Event Consumer API",
            "route": "/portal-events-consumer-api",
            "service": "portal-api",
            "file": "portal-events-consumer-api.yaml",
            "private": false,
            "section": "Poly Portal",
        },
        {
            "id": "private-portal-admin-api",
            "title": "PolyPortal Admin API",
            "route": "/private-portal-admin-api",
            "service": "portal-api",
            "file": "private-portal-admin-api.yaml",
            "private": true,
            "section": "Poly Portal",
        },
        {
            "id": "polyid-admin-api",
            "title": "PolyId Admin API",
            "route": "/polyid-admin-api",
            "service": "polyid-login",
            "file": "polyid-admin-api.yaml",
            "private": true,
            "section": "Poly Id",
        },
        {
            "id": "polyid-api",
            "title": "PolyId API",
            "route": "/polyid-api",
            "service": "polyid-login",
            "file": "polyid-api.yaml",
            "private": false,
            "section": "Poly Id",
        },
        {
            "id": "skill-catalog-api",
            "title": "Public Skill Catalog API",
            "route": "/skill-catalog-api",
            "service": "skill-index-core",
            "file": "skill-catalog-api.yaml",
            "private": false,
            "section": "Skill Catalog",
        },
    ];

const public_apis = all_apis.filter(a => a.private === false);

const App = () => {
    const [allowPrivate, setAllowPrivate] = useState(false);
    const [showPrivate, setShowPrivate] = useState(false);

    useEffect(() => {
        const fetchEnv = async () => {
            const DEBUG_SERVER = process.env['REACT_APP_DEBUG_SERVER'] || '';
            const url = DEBUG_SERVER + '/env';
            const options = {mode: 'cors'};
            const response = await fetch(url, options);
            if (response.ok) {
                const env = await response.json();
                setAllowPrivate(env["show_private_api"]);
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        fetchEnv();
    }, []);

    const toggleShow = (event) => {
        setShowPrivate(event.currentTarget.value === 'ecbern');
    }

    const apis = (allowPrivate && showPrivate) ? all_apis : public_apis

    return (
        <>
            {allowPrivate && <input id="password" onKeyUp={toggleShow}/>}
            <Switch>
                {React.Children.toArray(apis.map(api =>
                    <Route path={api.route}><Api service={api.service} file={api.file}/></Route>
                ))}
                <Route exact path='/'><Home key={'home'} apis={apis}/></Route>
            </Switch>
        </>
    )
}
export default App;
