import React from 'react';
import './Home.css';
import {Link} from "react-router-dom";

const Home = (props) => {
    const sections = [];

    const hasSection = section => sections.map(s => s.title).includes(section);
    const addSection = section => sections.push({"title": section, "apis": []});
    const addAPI = (section, api) => sections.filter(s => s.title === section).forEach(s => s.apis.push(api));

    props.apis.forEach(api => {
        if (!hasSection(api.section)) addSection(api.section);
        addAPI(api.section, api.id);
    });

    return (
        <div className="App">
            <h1>Public API index page</h1>
            <p> This page contains links to all of the POLYPOINT services public APIs. </p>
            <ul id="list">
                {
                    React.Children.toArray(sections.map((section) =>
                        <li>
                            <h4>{section.title}</h4>
                            <ul>
                                {
                                    section.apis.map(id => props.apis.find(api => api.id === id)).map((api) =>
                                        <li key={api.route}>
                                            <Link to={api.route}>{api.title}</Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Home;